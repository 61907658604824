.logo {
    max-width: 150px;
    height: auto;
}

.logo .circle-1, .logo .circle-1 path {
    animation: c1 2s;
    fill: none;
}

.logo .casteluc g {
    opacity: 0;
    transition: 0.5s ease-in-out;
}

.logo .square {
    transition: 0.2s ease-in-out;
}

.logo .c2 {
    transition: 0.5s ease-in-out;
}

/* ------------------------- LETTERS INITIAL POSITIONS ----------------------------- */
.logo .casteluc .a {
    transform: translateX(-120px);
}
.logo .casteluc .s {
    transform: translateX(-230px);
}
.logo .casteluc .t {
    transform: translateX(-325px);
}
.logo .casteluc .e {
    transform: translateX(-430px);
}
.logo .casteluc .l {
    transform: translateX(-510px);
}
.logo .casteluc .u {
    transform: translateX(-600px);
}
.logo .casteluc .cc {
    transform: translateX(-720px);
}
.logo .casteluc {
    transform: translateX(-225px);
}

/* ------------------------- ON HOVER ----------------------------- */
.logo:hover {
    cursor: pointer;
}

.logo:hover .casteluc g {
    transform: translateX(0);
    opacity: 1;
}

.logo:hover .icon .square {
    opacity: 0;
}

.logo:hover .icon .c2 {
    transform: translateX(715px);
}


