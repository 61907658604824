:root {
    font-size: 14px;
}

body {
    background-color: #202020;
}

#teste {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.5));
}
