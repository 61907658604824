html, body {
    margin: 0;
    padding: 0;
}

* {
    outline: none;
    border: 0;
    margin: 0;
}

body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
