.intro-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #202020;
    animation: hide-all 1s forwards 3.5s, display-none 0.1s forwards 4.5s;
}

.logo-intro {
    width: 90%;
    max-width: 600px;
}

.logo-intro .casteluc{
    opacity: 0;
    animation: casteluc 2s ease forwards 2s;
}

.logo-intro .icon {
    transform: translateX(37%);
}

.logo-intro .circle-1, .logo-intro .circle-1 path {
    animation: c1 2s;
    fill: none;
}

.logo-intro .c1 {
    filter: none;
    animation: c1-filter 5s forwards, casteluc-c1 1s ease forwards 2s;
}
.logo-intro .c2 {
    filter: none;
    animation: c2-filter 5s forwards, casteluc-c2 1s ease forwards 2s;
}
.logo-intro .square {
    filter: none;
    animation: square-filter 5s forwards, hide-square 0.4s ease forwards 2s;
}


/* ------------------------- ICON INITIAL CONFIGS ----------------------------- */
.logo-intro .c1 path, .logo-intro .c2 path, .logo-intro .square path {
    fill: transparent;
    stroke-width: 4px;
    animation: line-animation 2s ease forwards;
}

.logo-intro .c1 path, .logo-intro .c2 path {
    fill: transparent;
    stroke: #fafafa;
    stroke-dasharray: 766.84; 
    stroke-dashoffset: 766.84;
    animation: line-animation 2s ease forwards, white-animation 1s ease forwards 1.5s;
}

.logo-intro .square path {    
    fill: transparent;
    stroke: #00ffcc;
    stroke-dasharray: 397.48; 
    stroke-dashoffset: 397.48;
    animation: line-animation 2s ease forwards, green-animation 1s ease forwards 1.5s;
}

/* ------------------------- LETTERS INITIAL CONFIGS ----------------------------- */
.logo-intro .casteluc .c {
    transform: translateX(350px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .a {
    transform: translateX(235px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .s {
    transform: translateX(140px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .t {
    transform: translateX(35px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .e {
    transform: translateX(-50px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .l {
    transform: translateX(-135px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .u {
    transform: translateX(-245px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc .cc {
    transform: translateX(-355px);
    animation: casteluc-letter 0.6s ease forwards 2.2s;
}
.logo-intro .casteluc {
    transform: translateX(-150px);
}

/* ------------------------- STROKES AND FILTER ANIMATION ----------------------------- */
@keyframes line-animation {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes white-animation {
    to {
        fill: #fafafa;
        stroke:transparent;
    }
}

@keyframes green-animation {
    to {
        fill: #00ffcc;
        stroke: transparent;
    }
}

@keyframes c1-filter {to {filter: url(#circle-1)}}
@keyframes c2-filter {to {filter: url(#circle-2)}}
@keyframes square-filter {to {filter: url(#square)}}


/* ------------------------- LETTERS ANIMATION ----------------------------- */
@keyframes casteluc {to {opacity: 1;}}
@keyframes hide-square {to {opacity: 0;}}
@keyframes casteluc-letter {to {transform: translateX(0)}}
@keyframes casteluc-c1 {to {transform: translateX(-400px)}}
@keyframes casteluc-c2 {to {transform: translateX(400px)}}

@keyframes hide-all {
    to {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    } 
}
